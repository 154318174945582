@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;500&display=swap');

:root {
    --primary-color: #d5ebf8;
    --secondary-corlor: #ffff;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
*{
    font-family: 'Nunito', sans-serif;
}
textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {   
    border-color: rgba(161, 196, 219, 0.8);
    box-shadow: 0 1px 1px rgba(104, 137, 247, 0.075) inset, 0 0 8px rgba(116, 125, 245, 0.6);
    outline: 0 none;
}
.App{
    /* background-color: var(--primary-color); */
    height: 100vh;
}

.text-error{
    color:red;
    margin-left: 0.5rem;
    font-size: 15px;
}

a{
    text-decoration: none;
}